import './Blog.css'

function Blog() {
    return (
        <div>
            
        </div>
    );
}

export default Blog;